<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>历史面单</el-breadcrumb-item>

      </el-breadcrumb>
    </template>

    <el-form label-width="70px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="query.keyword" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>


    <basic-table ref="table" class="table" :query="query" @handler="fetchs">
      <template v-slot:status="scope">
        <span v-if="scope.row.status == 0" style="color:#909399">默认</span>
        <span v-else-if="scope.row.status == 1" style="color:blue">成功</span>
        <span v-else-if="scope.row.status == 2" style="color:#f56c6c">失败</span>
        <span v-else-if="scope.row.status == 3" style="color:#E6A23C">取消</span>
        <span v-else>无</span>
      </template>
      <template v-slot:toolbar="scope">
        <!-- <el-button v-if="scope.row.status == 1" type="primary" link size="small">
          [重打]
        </el-button> -->
        <el-button v-if="scope.row.status != 3" type="warning" link size="small" @click="cancelPrintEvent(scope.row.id)"
          style="margin-left:0;">
          [取消]
        </el-button>
      </template>
    </basic-table>

  </el-card>
</template>
<script>
import StaticDialog from "@/components/static-dialog.vue";
import BasicTable from "@/components/basic-table.vue";
import { cancelPrints } from "@/api/order";
export default {
  name: "OrderPrintInfo",
  components: { StaticDialog, BasicTable },
  data() {
    return {
      query: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },

          { prop: "orderNo", label: "订单", width: "190", searchable: true, showOverflowTooltip: true, },
          { prop: "dataFrom", label: "来源", width: "60", searchable: true, showOverflowTooltip: true, },
          { prop: "receiverName", label: "收件人", width: "70", searchable: true, showOverflowTooltip: true },
          { prop: "receiverMobile", label: "手机号", width: "90", searchable: true, showOverflowTooltip: true, },

          { prop: "receiverProvince", label: "省份", searchable: true, width: "80", showOverflowTooltip: true },
          { prop: "receiverCity", label: "城市", searchable: true, width: "80", showOverflowTooltip: true },
          { prop: "receiverArea", label: "县/区", searchable: true, width: "80", showOverflowTooltip: true },
          { prop: "receiverTown", label: "镇/街道", searchable: true, width: "80", showOverflowTooltip: true },
          { prop: "receiverAddress", label: "地址", width: "160", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "quantity", label: "数量", width: "45", showOverflowTooltip: true, },
          { type: "template", prop: "status", label: "状态", width: "50", showOverflowTooltip: true, },

          { prop: "expressCode", label: "快递编号", searchable: true, width: "70" },
          { prop: "expressName", label: "快递名称", searchable: true, width: "70", },
          //{ type: "hidden", prop: "expressBizCode", label: "快递业务编号", searchable: true },
          //{ type: "hidden", prop: "expressBizName", label: "快递业务名称", searchable: true },
          { prop: "expressPostNo", label: "快递单号", searchable: true, width: "140", showOverflowTooltip: true, },

          //{ type: "hidden", prop: "templateHtml", label: "打印模板", },
          { prop: "insureFee", label: "投保额", width: "60" },

          { prop: "senderName", label: "发件人", width: "70", },

          { prop: "remark", label: "备注", width: "150", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "reason", label: "原因", width: "90", showOverflowTooltip: true, },

          { prop: "createUserName", label: "创建人", searchable: true, width: "80", showOverflowTooltip: true },
          { prop: "createDate", label: "创建时间", sortable: true, width: "120", showOverflowTooltip: true },
          { prop: "updateUserName", label: "更新人", searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "updateDate", label: "更新时间", sortable: true, width: "120", showOverflowTooltip: true },
          { type: "toolbar", label: "", width: "80", showOverflowTooltip: true, },
        ],
        filters: [],
        sidx: "id",
        sord: "desc"
      }
    };
  },
  methods: {
    fetchs() {
      let { data, records, total, ...params } = { ...this.query };
      this.$kaung.search(params).then((res) => {
        this.query = { ...res.data };
      });
    },
    cancelPrintEvent(id) {
      let that = this;
      this.$confirm("此操作将取消已经打印下单的快递单, 是否继续?", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", })
        .then(() => {
          cancelPrints(id.split(',')).then((res) => {
            that.$message({
              type: res.type,
              message: res.content,
            });
            that.fetchs();
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "删除失败", });
        });
    },
  },
  watch: {
  },
  mounted() {
    //kaung插件初始化
    this.$kaung.init(this.$options.name);
    this.fetchs();
  },
  created: function () {
  },
  computed: {
  },
};
</script>
<style lang="scss" scoped></style>